import React, { ReactElement } from 'react'
import { InputProps } from '../../atoms/Input'
import InputElement from '../../atoms/Input/InputElement'
import styles from './Terms.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import { useSiteMetadata } from '../../../hooks/useSiteMetadata'

const query = graphql`
  query TermsQuery {
    terms: markdownRemark(fields: { slug: { eq: "/terms" } }) {
      html
    }
  }
`

export default function Terms(props: InputProps): ReactElement {
  const { termsOfServiceLink } = useSiteMetadata()
  return (
    <>
      <div className={styles.terms}>
        <a href={termsOfServiceLink}>Open Terms of service (PDF)</a>
      </div>
      <InputElement {...props} type="checkbox" />
    </>
  )
}
