// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-page-markdown-tsx": () => import("./../../../src/components/templates/PageMarkdown.tsx" /* webpackChunkName: "component---src-components-templates-page-markdown-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-asset-index-tsx": () => import("./../../../src/pages/asset/index.tsx" /* webpackChunkName: "component---src-pages-asset-index-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-publish-tsx": () => import("./../../../src/pages/publish.tsx" /* webpackChunkName: "component---src-pages-publish-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

